import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"

import Seo from "../components/seo"

const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query AllNewsQuery {
      allNewsJson {
        nodes {
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          date
          url
        }
      }
    }
  `)

  return (
    <div>
      <Seo title="NEWS" url="/news/" />
      <div tw="pt-10 pb-60">
        <GridLayout>
          <GridArea>
            <H4 tw="pt-7">NEWSROOM</H4>
            <H1 tw="pt-7">
              Company News &amp;
              <br /> Announcements
            </H1>
            <div tw=" flex flex-wrap">
              {data.allNewsJson.nodes.map(news => (
                <div tw="w-full lg:w-1/2 pt-20 flex items-start justify-center">
                  <a
                    href={news.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    tw="w-full lg:w-5/6"
                  >
                    {news.image ? (
                      <Img
                        fluid={news.image.childImageSharp.fluid}
                        alt={news.title}
                      />
                    ) : (
                      <div></div>
                    )}
                    <H3 tw="pt-7">{news.title}</H3>
                    <H4 tw="pt-4">{news.date}</H4>
                  </a>
                </div>
              ))}
            </div>
          </GridArea>
        </GridLayout>
      </div>
    </div>
  )
}

const GridLayout = styled.div`
  ${tw`grid grid-cols-12 h-full`}
`

const GridArea = styled.div`
  ${tw`col-start-2 col-end-12`}
`

const H1 = styled.div`
  ${tw`text-navy font-display-en font-bold text-4xl lg:text-7xl tracking-wide`}
`

const H3 = styled.div`
  ${tw`text-navy font-display-en font-bold text-xl lg:text-2xl tracking-wide`}
`

const H4 = styled.div`
  ${tw`text-navy font-body-en font-bold text-sm tracking-loose`}
`

export default NewsPage
